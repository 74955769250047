import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faSpinnerThird,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import { toast } from 'react-hot-toast'

import ButtonV2 from '~/components/ButtonV2'

type ToastProps = {
  message: string
  intent: 'success' | 'error' | 'loading' | 'warning' | 'info'
  dismissable?: boolean
  showTimer?: boolean
  toastId: string
  duration: number
  action?: {
    label: string
    onClick: () => void
  }
}

export default function Toast(props: ToastProps) {
  const { message, intent, toastId } = props
  let intentColor
  switch (intent) {
    case 'success':
      intentColor = 'success-base'
      break
    case 'error':
      intentColor = 'danger-base'
      break
    case 'loading':
      intentColor = 'gray-500'
      break
    case 'warning':
      intentColor = 'warning-base'
      break
    case 'info':
      intentColor = 'info-base'
      break
    default:
      intentColor = 'app-blue-base'
      break
  }

  switch (intent) {
    case 'success':
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faCheckCircle} className={`text-${intentColor}`} />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="font-bold">Success</div>
              <div className="text-sm">{message}</div>
            </div>
            <div className="flex items-center">
              {props.action && (
                <ButtonV2 intent={'gray-text-fixed'} onClick={props.action.onClick}>
                  {props.action.label}
                </ButtonV2>
              )}
            </div>
            {props.dismissable && (
              <button
                className="ml-4 px-2 py-1 cursor-pointer"
                onClick={() => toast.dismiss(toastId)}
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500" />
              </button>
            )}
          </div>
          {props.showTimer && (
            <div className="mt-2 -mx-[8px]">
              <DurationBar duration={props.duration} color={`bg-${intentColor}`} />
            </div>
          )}
        </div>
      )
    case 'error':
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faExclamationCircle} className={`text-${intentColor}`} />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="font-bold">Error</div>
              <div className="text-sm">{message}</div>
            </div>
            <div className="flex items-center">
              {props.action && (
                <ButtonV2 intent={'gray-text-fixed'} onClick={props.action.onClick}>
                  {props.action.label}
                </ButtonV2>
              )}
            </div>
            {props.dismissable && (
              <button
                className="ml-4 px-2 py-1 cursor-pointer"
                onClick={() => toast.dismiss(toastId)}
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500" />
              </button>
            )}
          </div>
          {props.showTimer && (
            <div className="mt-2 -mx-[8px]">
              <DurationBar duration={props.duration} color={`bg-${intentColor}`} />
            </div>
          )}
        </div>
      )
    case 'loading':
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-4"
            >
              <FontAwesomeIcon
                icon={faSpinnerThird}
                className={`text-${intentColor}`}
                style={{ animationDuration: '.5s' }}
                spin
              />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="text-sm">{message}</div>
            </div>
          </div>
        </div>
      )
    case 'warning':
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className={`text-${intentColor}`} />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="font-bold">Warning</div>
              <div className="text-sm">{message}</div>
            </div>
            <div className="flex items-center">
              {props.action && (
                <ButtonV2 intent={'gray-text-fixed'} onClick={props.action.onClick}>
                  {props.action.label}
                </ButtonV2>
              )}
            </div>
            {props.dismissable && (
              <button
                className="ml-4 px-2 py-1 cursor-pointer"
                onClick={() => toast.dismiss(toastId)}
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500" />
              </button>
            )}
          </div>
          {props.showTimer && (
            <div className="mt-2 -mx-[8px]">
              <DurationBar duration={props.duration} color={`bg-${intentColor}`} />
            </div>
          )}
        </div>
      )
    case 'info':
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faInfoCircle} className={`text-${intentColor}`} />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="font-bold">Info</div>
              <div className="text-sm">{message}</div>
            </div>
            <div className="flex items-center">
              {props.action && (
                <ButtonV2 intent={'gray-text-fixed'} onClick={props.action.onClick}>
                  {props.action.label}
                </ButtonV2>
              )}
            </div>
            {props.dismissable && (
              <button
                className="ml-4 px-2 py-1 cursor-pointer"
                onClick={() => toast.dismiss(toastId)}
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500" />
              </button>
            )}
          </div>
          {props.showTimer && (
            <div className="mt-2 -mx-[8px]">
              <DurationBar duration={props.duration} color={`bg-${intentColor}`} />
            </div>
          )}
        </div>
      )
    default:
      return (
        <div>
          <div className="flex items-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faInfoCircle} className={`text-${intentColor}`} />
            </motion.div>
            <div className="min-w-[200px] max-w-[220px]">
              <div className="font-bold">Info</div>
              <div className="text-sm">{message}</div>
            </div>
            <div className="flex items-center">
              {props.action && (
                <ButtonV2 intent={'gray-text-fixed'} onClick={props.action.onClick}>
                  {props.action.label}
                </ButtonV2>
              )}
            </div>
            {props.dismissable && (
              <button
                className="ml-4 px-2 py-1 cursor-pointer"
                onClick={() => toast.dismiss(toastId)}
              >
                <FontAwesomeIcon icon={faXmark} className="text-gray-500" />
              </button>
            )}
          </div>
          {props.showTimer && (
            <div className="mt-2 -mx-[8px]">
              <DurationBar duration={props.duration} color={`bg-${intentColor}`} />
            </div>
          )}
        </div>
      )
  }
}

function DurationBar(props: { duration: number; color: string; static?: boolean }) {
  return (
    <>
      {props.static ?
        <div className={`${props.color} h-[4px] -mb-[4px]`} />
      : <motion.div
          initial={{ width: '100&' }}
          animate={{ width: 0 }}
          transition={{
            duration: props.duration / 1000,
            ease: 'linear',
          }}
          className={`${props.color} h-[4px] -mb-[4px]`}
        />
      }
    </>
  )
}
